import { AnimatePresence, motion } from 'framer-motion'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import { SPACE } from 'Theme'
import { type NavigationItem as NavigationItemType } from 'types/graphql-generated'
import { NavigationItem } from './NavigationItem'
import { type DesktopMenuProps } from './types'

const DesktopMenu = dynamic<DesktopMenuProps>(
  () => import('./DesktopMenu').then((module) => module.DesktopMenu),
  {
    ssr: false,
  }
)
interface DesktopNavItemProps {
  title: string
  href: string
  dropDownMenu?: Array<NavigationItemType>
  displayBetaLabel?: boolean
  disabledMargin?: boolean
  onMouseEnter?: () => void
}

export const DesktopNavItem: React.FC<React.PWC<DesktopNavItemProps>> = ({
  title,
  href,
  dropDownMenu = [],
  displayBetaLabel,
  disabledMargin = false,
  onMouseEnter,
}) => {
  const [isHover, setIsHover] = useState(false)
  const router = useRouter()
  const menuRef = useRef(null)
  const hasSubMenu = dropDownMenu.length > 0

  const handleRouteChange = () => {
    setIsHover(false)
  }

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange)
    router.events.on('routeChangeError', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
      router.events.off('routeChangeError', handleRouteChange)
    }
  }, [router])

  useClickAway(menuRef, () => {
    if (menuRef.current?.contains(document.activeElement)) {
      setIsHover(false)
    }
  })

  return (
    <AnimatePresence>
      <motion.div
        key="navigation-items"
        onHoverStart={() => {
          setIsHover(true)
        }}
        onHoverEnd={() => {
          if (!menuRef.current?.contains(document.activeElement)) {
            setIsHover(false)
          }
        }}
        ref={menuRef}
      >
        <NavigationItem
          isActive={isHover}
          href={href}
          mr={{
            MOBILE: disabledMargin ? 0 : SPACE.PX_12,
            DESKTOP: disabledMargin ? 0 : SPACE.PX_36,
          }}
          hasSubMenu={hasSubMenu}
          displayBetaLabel={displayBetaLabel}
          onMouseEnter={onMouseEnter}
        >
          {title}
        </NavigationItem>
        <DesktopMenu
          dropDownMenu={dropDownMenu}
          isVisible={isHover && hasSubMenu}
        />
      </motion.div>
    </AnimatePresence>
  )
}
