import { type ReactQuery } from 'cms-api'

// Define a recursive type for NavigationItemWithParentWithChildren
type NavigationItemWithParentWithChildren =
  ReactQuery.NavigationItemWithParent & {
    children: NavigationItemWithParentWithChildren[]
  }

// Define the type for the formatted NavigationItem
export type NavigationItem = {
  title: string
  path: string
  children: NavigationItem[]
}

export type Navigation = {
  shopNavigation: ReactQuery.NavigationRenderResponse
  publicLayout: ReactQuery.PublicLayoutResponse
}

// Helper function to format items recursively
const formatItems = (
  items: NavigationItemWithParentWithChildren[],
  parentPath?: string
): NavigationItem[] =>
  items.map((item) => {
    const path = parentPath ? `${parentPath}/${item.path}` : `/${item.path}`
    return {
      title: item.title,
      path,
      children: formatItems(item.children, path), // Recursively format children
    }
  })

export const formatNavData = (
  navData: ReactQuery.NavigationItemWithParent[]
): NavigationItem[] => {
  // Create a lookup map for quick access to each item by ID
  const navMap = new Map<string, NavigationItemWithParentWithChildren>()

  // Initialize each item in navData with an empty children array
  navData.forEach((item) => {
    navMap.set(item.id.toString(), { ...item, children: [] })
  })

  // Structure for the formatted response
  const formattedResponse: NavigationItemWithParentWithChildren[] = []

  navData.forEach((item) => {
    const currentItem = navMap.get(item.id.toString())

    if (currentItem) {
      if (item.parent) {
        // Find the parent in the map and add this item as a child
        const parentItem = navMap.get(item.parent.id.toString())
        if (parentItem) {
          parentItem.children.push(currentItem)
        }
      } else {
        // If no parent, this is a root-level item
        formattedResponse.push(currentItem)
      }
    }
  })

  // Format and return the structured response
  return formatItems(formattedResponse)
}
