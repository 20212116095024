import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { get } from 'lodash'
import { useCallback, useState } from 'react'
import { PAYMENT_OPTIONS } from 'constants/common'
import { useCurrency } from 'providers/currency'
import { useUserAuth } from 'providers/userAuth'
import {
  useConfirmCheckoutMutation as useConfirmCheckoutMutationGenerated,
  useCreateCheckoutMutation as useCreateCheckoutMutationGenerated,
} from 'types/graphql-generated'
import { useGetBuyerPaymentMethod } from './useGetBuyerPaymentMethod'

export const useConfirmCheckoutMutation = () => {
  const [executeConfirmCheckout, { loading }] =
    useConfirmCheckoutMutationGenerated()

  const execute = useCallback(
    async ({ id }) => {
      try {
        const { data, errors } = await executeConfirmCheckout({
          variables: {
            id,
          },
        })
        return {
          error: errors
            ? new Error('Error during checkout confirmation')
            : undefined,
          data: data?.confirmCheckout,
        }
      } catch (error) {
        return {
          error,
        }
      }
    },
    [executeConfirmCheckout]
  )

  return [execute, { loading }] as const
}

export const useCreateCheckoutMutation = () => {
  const [executeCreateCheckout, { loading }] =
    useCreateCheckoutMutationGenerated()

  const execute = useCallback(
    async ({ input, freeOfCharge }) => {
      try {
        const { data, errors } = await executeCreateCheckout({
          variables: {
            input,
            requestSecret: !freeOfCharge && !input.stripePaymentMethodId,
          },
        })

        return {
          error: errors
            ? new Error('Error during checkout creation')
            : undefined,
          data: data?.createCheckout,
        }
      } catch (error) {
        return {
          error,
        }
      }
    },
    [executeCreateCheckout]
  )
  return [
    execute,
    {
      loading,
    },
  ] as const
}

export const useCheckout = ({ onError, onSuccess }) => {
  const selectedCurrency = useCurrency()
  const [checkoutState, setCheckoutState] = useState({
    data: null,
    error: null,
    isLoading: false,
  })

  const stripe = useStripe()
  const stripeElements = useElements()

  const { user, isClientSignedIn, isExpertSignedIn } = useUserAuth()

  const isGuest = !user || (!isClientSignedIn && !isExpertSignedIn)

  const [createCheckout] = useCreateCheckoutMutation()
  const [confirmCheckout] = useConfirmCheckoutMutation()
  const getBuyerPaymentMethod = useGetBuyerPaymentMethod({
    stripe,
    user,
    elements: stripeElements,
    isExpertSignedIn,
  })

  const LocalError = useCallback(
    ({ message, showToUser = false }) => {
      let errorMessage = message

      if (process.env.NEXT_PUBLIC_NODE_ENV === 'production' && !showToUser) {
        errorMessage = 'Something went wrong.'
      }

      const error = new Error(errorMessage)
      setCheckoutState({
        data: null,
        error,
        isLoading: false,
      })

      onError(errorMessage)
      return error
    },
    [setCheckoutState, onError]
  )

  const execute = useCallback(
    async ({
      email,
      paymentMethod,
      addresses,
      onStripePaymentConfirm,
      paymentMethodId,
      card,
      couponCode,
      promoCode,
      useTaxExemption,
      checkoutItems = [],
      freeOfCharge = false,
    }) => {
      if (!stripe || !stripeElements) {
        return LocalError({
          message: 'Stripe is not initialized yet',
        })
      }

      if (!paymentMethod) {
        return LocalError({
          message: 'Payment Method is not defined',
        })
      }

      if (paymentMethod === PAYMENT_OPTIONS.CARD && !card && !freeOfCharge) {
        return LocalError({
          message: 'PaymentMethod is card, but card is not defined',
        })
      }

      if (
        paymentMethod !== PAYMENT_OPTIONS.CARD &&
        (!paymentMethodId || !onStripePaymentConfirm) &&
        !freeOfCharge
      ) {
        return LocalError({
          message:
            'PaymentMethodId or stripe confirm callback is not available, client-side confirmation will fail.',
        })
      }

      if (checkoutItems.length === 0) {
        return LocalError({
          message: 'No checkout items.',
        })
      }

      if (isGuest && !email) {
        return LocalError({
          message: 'Tried to execute guest payment without email',
        })
      }

      setCheckoutState((oldState) => ({ ...oldState, isLoading: true }))

      const cardSetupIntentId =
        isGuest || paymentMethod !== PAYMENT_OPTIONS.CARD || freeOfCharge
          ? null
          : await getBuyerPaymentMethod(card)

      const checkoutResponse = await createCheckout({
        input: {
          addresses,
          email,
          checkoutItems,
          couponCode,
          promoCode,
          useTaxExemption,
          currencyIsoCode: selectedCurrency.currencyIsoCode,
          stripePaymentMethodId: cardSetupIntentId,
        },
        freeOfCharge,
      })

      // early return, if cardSetupIntentId is available == payment is finished server-side
      if (cardSetupIntentId || freeOfCharge) {
        setCheckoutState({
          error: checkoutResponse.error,
          data: checkoutResponse.data,
          isLoading: false,
        })
        if (checkoutResponse.error) {
          return LocalError({
            message:
              'The checkout process failed. Please try again or contact the support team.',
            showToUser: true,
          })
        }

        await onSuccess?.({ checkoutResponse, paymentMethod })

        return checkoutResponse
      }

      // payment was not finished on server, confirm payment on client-side
      const { id, stripePaymentId, stripeClientSecret } = get(
        checkoutResponse,
        'data',
        {
          id: undefined,
          stripePaymentId: undefined,
          stripeClientSecret: undefined,
        }
      )

      if (!stripePaymentId || checkoutResponse.error) {
        return LocalError({
          message: 'Error during stripe paymentIntent initialization',
        })
      }

      if (stripeClientSecret) {
        const { paymentIntent, error } = await stripe.confirmCardPayment(
          stripeClientSecret,
          {
            payment_method:
              paymentMethod === PAYMENT_OPTIONS.CARD
                ? (cardSetupIntentId ?? {
                    card: stripeElements.getElement(CardElement),
                    billing_details: {
                      email,
                    },
                  })
                : paymentMethodId,
          },
          { handleActions: paymentMethod === PAYMENT_OPTIONS.CARD }
        )

        if (error) {
          onStripePaymentConfirm?.('fail')
          return LocalError({
            message: 'Payment failed, please try another payment option.',
            showToUser: true,
          })
        }

        onStripePaymentConfirm?.('success')

        if (paymentIntent.status === 'requires_action') {
          const { error: actionConfirmError } =
            await stripe.confirmCardPayment(stripeClientSecret)
          if (actionConfirmError) {
            return LocalError({
              message: 'Payment failed. Please, try another payment option',
              showToUser: true,
            })
          }
        }

        const { error: confirmError } = await confirmCheckout({
          id,
        })

        if (confirmError) {
          // early return when confirm fails, User will be notified via Toast
          setCheckoutState({
            error: confirmError,
            data: paymentIntent,
            isLoading: false,
          })
          return confirmError
        }

        await onSuccess?.({ checkoutResponse, paymentMethod })

        setCheckoutState({
          error,
          data: paymentIntent,
          isLoading: false,
        })

        return checkoutResponse
      }

      return LocalError({
        message: 'Guest Payment failed for unknown reason',
      })
    },
    [
      stripe,
      stripeElements,
      isGuest,
      getBuyerPaymentMethod,
      createCheckout,
      selectedCurrency,
      LocalError,
      onSuccess,
      confirmCheckout,
    ]
  )

  return [
    execute,
    {
      ...checkoutState,
      isInitialLoading: !(stripe && stripeElements),
    },
  ] as const
}
