import { type ApolloClient, parser as queryParser } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import { get } from 'lodash'
import { type GetStaticPropsContext } from 'next'
import {
  ARTICLES_WIDGET_LIMIT,
  COLLECTION_WIDGET_LIMIT,
  COLLECTION_SORTER_FIELDS,
  SORTER_DIRECTION,
  PRODUCT_STATUSES,
  PRODUCT_VARIANT_STATUSES,
  REVALIDATE_INTERVAL_IN_SECONDS,
  EXPERTS_LISTING_WIDGET_LIMIT,
} from 'constants/common'
import { prepareBreadcrumbsProps } from 'routes/utils/prepareGetStaticBreadcrumbsProps'
import { prepareNavigationProps } from 'routes/utils/prepareGetStaticNavigationProps'
import { CmsWidgetByIdDocument as CMS_WIDGET_BY_ID_QUERY } from 'types/graphql-generated'
import { initializeApollo } from 'utils/apolloClient'

export const prepareGetStaticProps = async (
  context: GetStaticPropsContext,
  {
    slug,
    query,
    route,
  }: {
    slug: string
    query: any
    route?: string
  },
  inputApolloClient?: ApolloClient<any>
) => {
  try {
    const apolloClient = inputApolloClient || initializeApollo()
    const navigation = await prepareNavigationProps()

    const breadcrumbs = await prepareBreadcrumbsProps(
      apolloClient,
      route ?? `/${slug}`
    )
    const { data } = await apolloClient.query({
      query,
      variables: {
        slug,
        widgetsDefaultInputs: {
          cmsExpertsInput: {
            page: 1,
          },
          cmsExpertsListingWidgetInput: {
            pagination: { page: 1, limit: EXPERTS_LISTING_WIDGET_LIMIT },
          },
          cmsArticlesInput: {
            page: 1,
            limit: ARTICLES_WIDGET_LIMIT.DESKTOP,
          },
          cmsMultiCollectionProductsInput: {
            pagination: {
              page: 1,
              limit: COLLECTION_WIDGET_LIMIT.DESKTOP,
            },
            sorter: {
              field: COLLECTION_SORTER_FIELDS.POSITION_INDEX_WEIGHT,
              direction: SORTER_DIRECTION.ASC,
            },
            filter: {
              statuses: [PRODUCT_STATUSES.PUBLISHED],
              variantStatuses: [PRODUCT_VARIANT_STATUSES.PUBLISHED],
            },
          },
        },
      },
    })

    const operationName = queryParser(query).name
    const widgets = get(data, [operationName, 'cmsPage', 'widgets'], [])

    // add widget to correct field in the cache.
    const widgetsToCache = widgets.filter((widget) =>
      [
        'CMSExpertsWidget',
        'CMSArticlesWidget',
        'CMSMultiCollectionWidget',
      ].includes(widget.content.__typename)
    )
    widgetsToCache.forEach((widget) => {
      /**
       *
       * `writeQuery` is used to write data into the cache without additional network requests
       *  We have to add these initial data manually into the cache because we are using different queries for each paginated widget.
       *  We have to do this with all paginated widgets.
       * https://www.apollographql.com/docs/react/caching/cache-interaction/#writequery
       *
       * */
      apolloClient.writeQuery({
        query: CMS_WIDGET_BY_ID_QUERY,
        data: { cmsWidgetById: widget },
        variables: {
          input: {
            widgetId: widget.id,
          },
        },
      })
    })

    return {
      props: {
        initialApolloState: apolloClient.cache.extract(),
        slug,
        navigation,
        breadcrumbs,
      },
      revalidate: REVALIDATE_INTERVAL_IN_SECONDS,
    }
  } catch (error) {
    datadogRum.addError(error)
    return {
      notFound: true,
      props: { slug: null, navigation: [], breadcrumbs: [] },
      revalidate: REVALIDATE_INTERVAL_IN_SECONDS,
    }
  }
}
