import { reduce } from 'lodash'
import {
  MULTI_COLLECTION_WIDGET_TYPE,
  TEXT_EDITOR_FORMAT,
  COLLECTION_HEADER_WIDGET_TYPE,
  CATEGORY_HEADER_WIDGET_TYPE,
  ALPHABETICAL_INDEX_WIDGET_TYPE,
} from 'constants/common'
import {
  COLLECTION_SORT_FIELDS,
  SORT_DIRECTION_ENUM,
} from 'routes/admin/constants/general'
import { type CmsWidgetInput } from 'types/graphql-generated'
import { isRichTextEditorEmpty } from 'utils/validation'
import { ALLOWED_WIDGET_TYPES } from './constants'
import { widgetItemsContentFormatter } from './WidgetItems/contentFormatters'

const DEFAULT_WIDGET_CONTENT_FORMATTERS = reduce(
  ALLOWED_WIDGET_TYPES,
  (acc, widgetType) => ({
    ...acc,
    [widgetType]: (content: unknown) => ({
      [`cms${widgetType.slice(3)}`]: content,
    }),
  }),
  {}
)

const getGalleryWidgetCaptionValue = (caption) => {
  if (typeof caption === 'string') {
    return caption
  }
  if (!caption || isRichTextEditorEmpty(caption)) {
    return null
  }
  return caption.toString(TEXT_EDITOR_FORMAT.MARKDOWN)
}

const getListingWidgetsIds = (cmsListingWidgets) =>
  reduce(
    cmsListingWidgets,
    (acc, { id }) => (id ? [...acc, parseInt(id)] : acc),
    []
  )

const formatGalleryWidgetContent = (content) => {
  const { cmsGalleryWidgetItems, title } = content
  return {
    cmsGalleryWidget: {
      title: title ?? '',
      ...content,
      cmsGalleryWidgetItems: (cmsGalleryWidgetItems || []).map(
        ({
          __typename,
          attachment,
          expert,
          clientId,
          caption,
          ...cmsGalleryWidgetItem
        }) => ({
          expertId: expert?.value?.id ? parseInt(expert.value.id) : null,
          attachmentId: parseInt(attachment.id),
          caption: getGalleryWidgetCaptionValue(caption),
          ...cmsGalleryWidgetItem,
        })
      ),
    },
  }
}

const formatHeroWidgetContent = ({
  attachment,
  title = '',
  ...restContent
}) => ({
  cmsHeroWidget: {
    attachmentId: parseInt(attachment.id),
    title,
    ...restContent,
  },
})

const formatHeroMainWidgetContent = ({
  attachment,
  headline,
  subheadline,
  mainButtonText,
  mainButtonLink,
  hasSecondaryButton,
  secondaryButtonText = '',
  secondaryButtonLink = '',
  ...restContent
}) => ({
  cmsHeroMainWidget: {
    attachmentId: parseInt(attachment.id),
    headline,
    subheadline,
    mainButtonText,
    mainButtonLink,
    hasSecondaryButton,
    secondaryButtonText,
    secondaryButtonLink,
    ...restContent,
  },
})

const formatTestimonialsWidgetContent = (content) => {
  const { cmsTestimonialsWidgetItems } = content
  return {
    cmsTestimonialsWidget: {
      ...content,
      cmsTestimonialsWidgetItems: (cmsTestimonialsWidgetItems || []).map(
        ({ __typename, ...cmsTestimonialsWidgetItem }) => ({
          ...cmsTestimonialsWidgetItem,
        })
      ),
    },
  }
}

const formatVideoWidgetContent = ({
  thumbnailAttachment,
  videoAttachment,
  ...restContent
}) => ({
  cmsVideoWidget: {
    videoAttachmentId: parseInt(videoAttachment.id),
    thumbnailAttachmentId: parseInt(thumbnailAttachment.id),
    ...restContent,
  },
})

const formatVideoSectionWidgetContent = ({
  thumbnailAttachment,
  videoAttachment,
  ...restContent
}) => ({
  cmsVideoSectionWidget: {
    videoAttachmentId: parseInt(videoAttachment.id),
    thumbnailAttachmentId: parseInt(thumbnailAttachment.id),
    ...restContent,
  },
})

const formatExpertsWidgetContent = (content) => {
  const { cmsExpertsWidgetItems } = content
  return {
    cmsExpertsWidget: {
      ...content,
      cmsExpertsWidgetItems: (cmsExpertsWidgetItems || []).map(
        ({ __typename, expert, clientId, ...cmsExpertsWidgetItem }) => ({
          expertId: expert?.value?.id ? parseInt(expert.value.id) : null,
          ...cmsExpertsWidgetItem,
        })
      ),
    },
  }
}

const formatArticlesWidgetContent = (content) => {
  const { cmsArticlesWidgetItems, title } = content
  return {
    cmsArticlesWidget: {
      title: title ?? '',
      ...content,
      cmsArticlesWidgetItems: (cmsArticlesWidgetItems || []).map(
        ({ __typename, article, clientId, ...cmsArticlesWidgetItem }) => ({
          cmsArticlePageId: article?.id ? parseInt(article.id) : null,
          ...cmsArticlesWidgetItem,
        })
      ),
    },
  }
}

const formatSpotlightWidgetContent = ({
  attachment,
  expert,
  ...restContent
}) => ({
  cmsSpotlightWidget: {
    attachmentId: parseInt(attachment.id),
    expertId: expert?.value?.id ? parseInt(expert.value.id) : null,
    ...restContent,
  },
})

const formatRichTextWidgetContent = ({ richText, ...restContent }) => ({
  cmsRichTextWidget: {
    richText: richText.toString(TEXT_EDITOR_FORMAT.MARKDOWN),
    ...restContent,
  },
})

const formatCategoryHeaderWidgetContent = ({
  categoryHeaderWidgetType,
  expert,
  cmsCategoryHeaderWidgetItems,
  ...restContent
}) => ({
  cmsCategoryHeaderWidget: {
    expertId: expert?.value?.id ? parseInt(expert.value.id) : null,
    type: categoryHeaderWidgetType ?? CATEGORY_HEADER_WIDGET_TYPE.SINGLE_IMAGE,
    cmsCategoryHeaderWidgetItems: (cmsCategoryHeaderWidgetItems || []).map(
      (
        { __typename, attachment, ...restItemContent },
        positionIndexWeight
      ) => ({
        attachmentId: parseInt(attachment.id),
        ...restItemContent,
        positionIndexWeight,
      })
    ),
    ...restContent,
  },
})

const formatCollectionHeaderWidgetContent = ({
  headerImageAttachment,
  mobileImageAttachment,
  isWhiteTextColor,
  ...restContent
}) => ({
  cmsCollectionHeaderWidget: {
    headerImageAttachmentId: parseInt(headerImageAttachment.id),
    mobileImageAttachmentId: mobileImageAttachment?.id
      ? parseInt(mobileImageAttachment.id)
      : null,
    collectionHeaderType: isWhiteTextColor
      ? COLLECTION_HEADER_WIDGET_TYPE.WHITE_TEXT_ON_DARK
      : COLLECTION_HEADER_WIDGET_TYPE.DEFAULT,
    ...restContent,
  },
})

const formatSingleImageWidgetContent = ({
  attachment,
  caption,
  ...restContent
}) => ({
  cmsSingleImageWidget: {
    attachmentId: parseInt(attachment.id),
    caption: caption.toString(TEXT_EDITOR_FORMAT.MARKDOWN),
    ...restContent,
  },
})

const formatDoubleImageWidgetContent = ({
  firstAttachment,
  secondAttachment,
  firstCaption,
  secondCaption,
  ...restContent
}) => ({
  cmsDoubleImageWidget: {
    firstAttachmentId: parseInt(firstAttachment.id),
    secondAttachmentId: parseInt(secondAttachment.id),
    firstCaption: firstCaption
      ? firstCaption.toString(TEXT_EDITOR_FORMAT.MARKDOWN)
      : '',
    secondCaption: secondCaption
      ? secondCaption.toString(TEXT_EDITOR_FORMAT.MARKDOWN)
      : '',
    ...restContent,
  },
})

const formatBannerWidgetContent = ({ expert, ...rest }) => ({
  cmsBannerWidget: {
    expertId: expert?.value?.id ? parseInt(expert.value.id) : null,
    ...rest,
  },
})

const formatProductsWidgetContent = (content) => {
  const { cmsProductsWidgetItems } = content
  return {
    cmsProductsWidget: {
      ...content,
      cmsProductsWidgetItems: (cmsProductsWidgetItems || []).map(
        ({ __typename, attachment, clientId, ...cmsProductsWidgetItem }) => ({
          attachmentId: parseInt(attachment.id),
          ...cmsProductsWidgetItem,
        })
      ),
    },
  }
}

const formatQAWidgetContent = (content) => {
  const { cmsQAWidgetItems } = content
  return {
    cmsQAWidget: {
      ...content,
      cmsQAWidgetItems: (cmsQAWidgetItems || []).map(
        ({ __typename, clientId, answer, ...cmsQAWidgetItem }) => ({
          answer: answer ? answer.toString(TEXT_EDITOR_FORMAT.MARKDOWN) : '',
          ...cmsQAWidgetItem,
        })
      ),
    },
  }
}

const formatMultiCollectionWidgetContent = (content) => {
  const {
    isSortEnabled,
    defaultSortField,
    defaultSortDesc,
    cmsMultiCollectionWidgetItems,
    cmsListingWidgets,
    multiCollectionWidgetType = MULTI_COLLECTION_WIDGET_TYPE.DEFAULT,
    isShopAllProductsButtonEnabled,
    headline,
    cmsShowroomPage,
    firstPublishedAtFilter,
    isFirstPublishedAtFilterEnabled,
    expert,
    ...restContent
  } = content
  let defaultSortDirection = null
  if (isSortEnabled) {
    defaultSortDirection =
      defaultSortField !== COLLECTION_SORT_FIELDS.POSITION_INDEX_WEIGHT &&
      defaultSortDesc
        ? SORT_DIRECTION_ENUM.DESC
        : SORT_DIRECTION_ENUM.ASC
  }
  const isDefaultType =
    multiCollectionWidgetType === MULTI_COLLECTION_WIDGET_TYPE.DEFAULT
  return {
    cmsMultiCollectionWidget: {
      type: multiCollectionWidgetType,
      headline: isDefaultType ? '' : (headline ?? ''),
      isShopAllProductsButtonEnabled: isDefaultType
        ? false
        : isShopAllProductsButtonEnabled,
      isSortEnabled: isDefaultType ? isSortEnabled : false,
      defaultSortField:
        isSortEnabled && isDefaultType
          ? (defaultSortField ?? COLLECTION_SORT_FIELDS.POSITION_INDEX_WEIGHT)
          : null,
      defaultSortDirection,
      cmsListingWidgets: isDefaultType
        ? getListingWidgetsIds(cmsListingWidgets)
        : [],
      cmsShowroomPageId: cmsShowroomPage?.id
        ? parseInt(cmsShowroomPage.id)
        : null,
      expertId: expert?.id ? parseInt(expert.id) : null,
      ...restContent,
      firstPublishedAtFilter,
      cmsMultiCollectionWidgetItems: (cmsMultiCollectionWidgetItems || []).map(
        (
          { __typename, collection, ...restItemContent },
          positionIndexWeight
        ) => ({
          collectionId: collection?.id ? parseInt(collection.id) : null,
          ...restItemContent,
          positionIndexWeight,
        })
      ),
    },
  }
}

const formatExpertCollectionsWidgetContent = ({
  cmsExpertCollectionsWidgetItems,
  ...restContent
}) => ({
  cmsExpertCollectionsWidget: {
    ...restContent,
    cmsExpertCollectionsWidgetItems: (
      cmsExpertCollectionsWidgetItems || []
    ).map(
      (
        {
          __typename,
          cmsShowroomPage,
          previewImageAttachment,
          profileImageAttachment,
          clientId,
          expert,
          ...restItemContent
        },
        positionIndexWeight
      ) => ({
        cmsShowroomPageId: cmsShowroomPage?.id
          ? parseInt(cmsShowroomPage.id)
          : null,
        expertId: expert?.id ? parseInt(expert.id) : null,
        previewImageAttachmentId: parseInt(previewImageAttachment.id),
        profileImageAttachmentId: profileImageAttachment?.id
          ? parseInt(profileImageAttachment.id)
          : null,
        ...restItemContent,
        positionIndexWeight,
      })
    ),
  },
})

const formatCollectionsCarouselWidgetContent = ({
  cmsCollectionsCarouselWidgetItems,
  title,
  cmsShowroomPage: cmsShowroomPageLink,
  ...restContent
}) => ({
  cmsCollectionsCarouselWidget: {
    title: title ?? '',
    ...restContent,
    cmsShowroomPageId: cmsShowroomPageLink?.id
      ? parseInt(cmsShowroomPageLink.id)
      : null,
    cmsCollectionsCarouselWidgetItems: (
      cmsCollectionsCarouselWidgetItems || []
    ).map(
      (
        {
          __typename,
          cmsShowroomPage,
          previewImageAttachment,
          profileImageAttachment,
          clientId,
          ...restItemContent
        },
        positionIndexWeight
      ) => ({
        cmsShowroomPageId: cmsShowroomPage?.id
          ? parseInt(cmsShowroomPage.id)
          : null,
        previewImageAttachmentId: parseInt(previewImageAttachment.id),
        profileImageAttachmentId: profileImageAttachment?.id
          ? parseInt(profileImageAttachment.id)
          : null,
        ...restItemContent,
        positionIndexWeight,
      })
    ),
  },
})

const formatIntroductionWidgetContent = ({ attachment, ...restContent }) => ({
  cmsIntroductionWidget: {
    attachmentId: parseInt(attachment.id),
    ...restContent,
  },
})

const formatAlphabeticalIndexWidgetContent = ({
  alphabeticalIndexWidgetType,
  ...restContent
}) => ({
  cmsAlphabeticalIndexWidget: {
    type: alphabeticalIndexWidgetType ?? ALPHABETICAL_INDEX_WIDGET_TYPE.BRAND,
    ...restContent,
  },
})

const formatHeroCarouselWidgetContent = (content) => ({
  cmsHeroCarouselWidget: {
    ...content,
    isAutoRotationEnabled: content.isAutoRotationEnabled ?? false,
    cmsHeroCarouselWidgetItems: (content.cmsHeroCarouselWidgetItems || []).map(
      ({ attachment, __typename, clientId, ...rest }, positionIndexWeight) => ({
        ...rest,
        attachmentId: parseInt(attachment.id),
        positionIndexWeight,
      })
    ),
  },
})

const formatCardNavigationWidgetContent = ({
  cmsCardNavigationWidgetPlacement,
  hasButton,
  cmsCardNavigationWidgetTitle,
  cmsCardNavigationWidgetItems,
  ...restContent
}) => ({
  cmsCardNavigationWidget: {
    placement: cmsCardNavigationWidgetPlacement,
    title: cmsCardNavigationWidgetTitle,
    cmsCardNavigationWidgetItems: (cmsCardNavigationWidgetItems || []).map(
      (
        { attachment, cmsPage, __typename, clientId, ...rest },
        positionIndexWeight
      ) => ({
        attachmentId: parseInt(attachment.id),
        cmsPageId: parseInt(cmsPage.id),
        positionIndexWeight,
        ...rest,
      })
    ),
    ...restContent,
  },
})

const formatSectionWidgetContent = ({
  widgetItems,
  ...rest
}): Pick<CmsWidgetInput, 'cmsSectionWidget'> => ({
  cmsSectionWidget: {
    widgetItems: widgetItemsContentFormatter(widgetItems),
    ...rest,
  },
})

export const WIDGET_CONTENT_FORMATTERS_MAP = {
  ...DEFAULT_WIDGET_CONTENT_FORMATTERS,
  [ALLOWED_WIDGET_TYPES.GALLERY]: formatGalleryWidgetContent,
  [ALLOWED_WIDGET_TYPES.HERO_LEGACY]: formatHeroWidgetContent,
  [ALLOWED_WIDGET_TYPES.HERO_MAIN]: formatHeroMainWidgetContent,
  [ALLOWED_WIDGET_TYPES.TESTIMONIALS]: formatTestimonialsWidgetContent,
  [ALLOWED_WIDGET_TYPES.VIDEO]: formatVideoWidgetContent,
  [ALLOWED_WIDGET_TYPES.VIDEO_SECTION]: formatVideoSectionWidgetContent,
  [ALLOWED_WIDGET_TYPES.EXPERTS]: formatExpertsWidgetContent,
  [ALLOWED_WIDGET_TYPES.ARTICLES]: formatArticlesWidgetContent,
  [ALLOWED_WIDGET_TYPES.SPOTLIGHT]: formatSpotlightWidgetContent,
  [ALLOWED_WIDGET_TYPES.RICH_TEXT]: formatRichTextWidgetContent,
  [ALLOWED_WIDGET_TYPES.CATEGORY_HEADER]: formatCategoryHeaderWidgetContent,
  [ALLOWED_WIDGET_TYPES.COLLECTION_HEADER]: formatCollectionHeaderWidgetContent,
  [ALLOWED_WIDGET_TYPES.BANNER]: formatBannerWidgetContent,
  [ALLOWED_WIDGET_TYPES.SINGLE_IMAGE]: formatSingleImageWidgetContent,
  [ALLOWED_WIDGET_TYPES.PRODUCTS]: formatProductsWidgetContent,
  [ALLOWED_WIDGET_TYPES.DOUBLE_IMAGE]: formatDoubleImageWidgetContent,
  [ALLOWED_WIDGET_TYPES.QA]: formatQAWidgetContent,
  [ALLOWED_WIDGET_TYPES.MULTI_COLLECTION]: formatMultiCollectionWidgetContent,
  [ALLOWED_WIDGET_TYPES.EXPERT_COLLECTIONS]:
    formatExpertCollectionsWidgetContent,
  [ALLOWED_WIDGET_TYPES.COLLECTIONS_CAROUSEL]:
    formatCollectionsCarouselWidgetContent,
  [ALLOWED_WIDGET_TYPES.INTRODUCTION]: formatIntroductionWidgetContent,
  [ALLOWED_WIDGET_TYPES.ALPHABETICAL_INDEX]:
    formatAlphabeticalIndexWidgetContent,
  [ALLOWED_WIDGET_TYPES.HERO_CAROUSEL]: formatHeroCarouselWidgetContent,
  [ALLOWED_WIDGET_TYPES.CARD_NAVIGATION]: formatCardNavigationWidgetContent,
  [ALLOWED_WIDGET_TYPES.SECTION]: formatSectionWidgetContent,
}
