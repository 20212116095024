import { datadogRum } from '@datadog/browser-rum'
import { Axios } from 'cms-api'
import { REVALIDATE_INTERVAL_IN_SECONDS } from 'constants/common'
import { initializeApollo } from 'utils/apolloClient'

export const prepareNavigationProps = async () => {
  try {
    // ToDo: Make sure that PublicLayout query is in sync
    const publicLayout = await Axios.client().getPublicLayout({
      populate: {
        widgets: {
          on: {
            'widgets.feature-bar-widget': {
              populate: '*',
            },
            'widgets.public-layout-menu-widget': {
              populate: '*',
            },
          },
        },
      } as never,
    })

    const shopNavigation =
      await Axios.client().getNavigationRenderIdOrSlug('shop-navigation')

    return {
      shopNavigation,
      publicLayout,
    }
  } catch (error) {
    datadogRum.addError(error)
    return []
  }
}

export const prepareGetStaticNavigationProps = async () => {
  try {
    const apolloClient = initializeApollo()
    const navigation = await prepareNavigationProps()

    return {
      props: {
        navigation,
        // TODO fix initialApolloState after getDataFromTree implementation #1545
        initialApolloState: apolloClient.cache.extract(),
      },
      revalidate: REVALIDATE_INTERVAL_IN_SECONDS,
    }
  } catch (error) {
    datadogRum.addError(error)
    return {
      notFound: true,
      props: { navigation: [] },
      revalidate: REVALIDATE_INTERVAL_IN_SECONDS,
    }
  }
}
