import React from 'react'
import {
  type ColorProps,
  type SpaceProps,
  type TypographyProps,
  type WidthProps,
} from 'styled-system'
import { Text } from 'components/Typography'
import { ROUTE } from 'constants/routes'
import { FONT_STACK } from 'Theme'
import Link, { type LinkProps } from './Link'

export interface LinkWithTextProps
  extends Pick<LinkProps, 'onClick' | 'isExternal' | 'target'>,
    TypographyProps,
    WidthProps,
    ColorProps,
    SpaceProps {
  dataTestId?: string
  href: string
  'data-test-id'?: string
  'aria-label'?: string | null
  style?: any
}

export const LinkWithText: React.FC<React.PWC<LinkWithTextProps>> = ({
  children,
  href = ROUTE.FRONT_PAGE(),
  isExternal,
  target,
  width,
  onClick,
  'aria-label': ariaLabel = null,
  ...rest
}) => (
  <Link
    href={href}
    isExternal={isExternal}
    target={target}
    width={width}
    onClick={onClick}
    aria-label={
      ariaLabel ?? (typeof children === 'string' ? children : undefined)
    }
  >
    <Text fontFamily={FONT_STACK.SANS} {...rest}>
      {children}
    </Text>
  </Link>
)
