import { type ReactQuery } from 'cms-api'
import React from 'react'
import styled from 'styled-components'
import { Box, Container, Flex } from 'components/Layout'
import { StyledLink } from 'components/Link'
import { Text } from 'components/Typography'
import { COLOR_INTENT, FONT_SIZE, SPACE } from 'Theme'

type TrendingLinkItem = {
  id: number
  label: string
  link: string
}

interface TrendingLinksProps {
  publicLayoutMenuWidget: ReactQuery.WidgetsPublicLayoutMenuWidgetComponent
}

const TrendingLink = styled(StyledLink)`
  background-color: ${COLOR_INTENT.MENU.TRENDING_LINK.BACKGROUND};
  font-size: ${FONT_SIZE.PX_13};
  color: ${COLOR_INTENT.MENU.TRENDING_LINK.COLOR} !important;
  padding: ${SPACE.PX_5} ${SPACE.PX_9};
  height: auto;
  &:hover {
    background-color: ${COLOR_INTENT.MENU.TRENDING_LINK.BACKGROUND};
  }
`

export const TrendingLinks: React.FC<React.PWC<TrendingLinksProps>> = ({
  publicLayoutMenuWidget,
}) => {
  const { trendingLinks } = publicLayoutMenuWidget
  return (
    <Box mb={SPACE.PX_15}>
      <Container>
        <Flex
          gap={SPACE.PX_14}
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
        >
          <Text
            fontSize={FONT_SIZE.PX_13}
            color={COLOR_INTENT.GRAY_90}
            letterSpacing="0.06em"
          >
            TRENDING:{' '}
          </Text>
          {trendingLinks.map((link: TrendingLinkItem, index) => (
            <TrendingLink key={index} href={link.link}>
              {link.label}
            </TrendingLink>
          ))}
        </Flex>
      </Container>
    </Box>
  )
}
