import { get } from 'lodash'
import styled from 'styled-components'
import {
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_MOBILE_PARTIAL,
  HEADER_HEIGHT_MOBILE_FULL,
} from 'constants/common'
import { BOX_SHADOW, COLOR_INTENT, mq } from 'Theme'

const HEADER_TRANSITION_DURATION_IN_MS = 300

const MQ_FROM_TABLET = get(mq, ['from', 'TABLET'])

export const HeaderWrapper = styled.header`
  height: ${({ isCheckoutHeader = false, isSearchInputFocused = false }) =>
    isSearchInputFocused || isCheckoutHeader
      ? HEADER_HEIGHT_MOBILE_PARTIAL
      : HEADER_HEIGHT_MOBILE_FULL};
  ${({ isCheckoutHeader = false }) =>
    isCheckoutHeader && `background-color: ${COLOR_INTENT.HEADER.WRAPPER};`}
  ${MQ_FROM_TABLET`
    height: ${HEADER_HEIGHT_DESKTOP};
  `}
  display: flex;
  transition: all ${HEADER_TRANSITION_DURATION_IN_MS}ms;
  transition-delay: 100ms;
  box-shadow: ${({ showShadow, isShopHovered }) =>
    `${showShadow && !isShopHovered ? BOX_SHADOW.ELEVATION_1 : 'none'}`};
  border-bottom: ${({ showShadow, isShopHovered }) =>
    `${showShadow && !isShopHovered ? 'none' : '1px solid #949494'}`};
  ${mq.to.TABLET`
    transform: ${({ isSearchInputFocused }) =>
      isSearchInputFocused
        ? `translateY(-${HEADER_HEIGHT_MOBILE_PARTIAL})`
        : 'translateY(0)'};
  `}
`
