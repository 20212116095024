import {
  BlocksRenderer,
  type BlocksContent,
} from '@strapi/blocks-react-renderer'
import { type ReactQuery } from 'cms-api'
import React from 'react'
import styled from 'styled-components'
import { Flex } from 'components/Layout'
import { LinkWithText } from 'components/Link'
import { Text } from 'components/Typography'
import { COLOR_INTENT, FONT_SIZE, FONT_STACK, SPACE, Z_INDEX } from 'Theme'

const StyledLinkWithText = styled(LinkWithText)`
  text-decoration: underline;
`

interface TradeProgramRibbonProps extends React.PWC<unknown> {
  featureBarWidget: ReactQuery.WidgetsFeatureBarWidgetComponent
}

export const TradeProgramRibbon = React.forwardRef<
  HTMLDivElement,
  TradeProgramRibbonProps
>(({ featureBarWidget }, ref) => (
  <Flex
    ref={ref}
    px={{ MOBILE: SPACE.PX_6, TABLET: SPACE.PX_9 }}
    backgroundColor={COLOR_INTENT.TRADE_PROGRAM_RIBBON.BACKGROUND}
    alignItems="center"
    justifyContent="center"
    zIndex={Z_INDEX.TRADE_PROGRAM_RIBBON}
    py={SPACE.PX_12}
  >
    {featureBarWidget.text && (
      <BlocksRenderer
        content={featureBarWidget.text as BlocksContent}
        blocks={{
          paragraph: ({ children }) => (
            <Text
              fontSize={FONT_SIZE.PX_14}
              color={COLOR_INTENT.TRADE_PROGRAM_RIBBON.TEXT}
              fontFamily={FONT_STACK.SANS}
            >
              {children}
            </Text>
          ),
          link: ({ children, url }) => (
            <StyledLinkWithText
              color={COLOR_INTENT.TRADE_PROGRAM_RIBBON.TEXT}
              href={url}
              fontSize={FONT_SIZE.PX_14}
              fontFamily={FONT_STACK.SANS}
            >
              {children}
            </StyledLinkWithText>
          ),
        }}
      />
    )}
  </Flex>
))

TradeProgramRibbon.displayName = 'TradeProgramRibbon'
