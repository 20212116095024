import { Accordion, Text } from '@mantine/core'
import { useClickOutside, useWindowScroll } from '@mantine/hooks'
import { type ReactQuery } from 'cms-api'
import Link from 'next/link'
import { rem } from 'polished'
import React, { useEffect, useState } from 'react'
import { Box, Flex } from 'components/Layout'
import { LinkWithText, type LinkWithTextProps } from 'components/Link'
import { HEADER_HEIGHT_MOBILE_PARTIAL } from 'constants/common'
import { ROUTE } from 'constants/routes'
import { type NavigationItem } from 'routes/utils/formatNavMenu'
import { COLOR_INTENT, COLOR_NAMES, FONT_SIZE, FONT_WEIGHT, SPACE } from 'Theme'

const SIDE_MENU_CONTENT = rem(50)

const MenuLink: React.FC<React.PWC<LinkWithTextProps>> = ({
  children,
  href,
  onClick,
}) => (
  <LinkWithText
    fontSize={FONT_SIZE.PX_20}
    fontWeight={FONT_WEIGHT.MEDIUM}
    href={href}
    color={COLOR_INTENT.HEADER.ITEM_WITH_IMAGE_TEXT.DEFAULT}
    onClick={onClick}
  >
    {children}
  </LinkWithText>
)

interface MobileMenuProps {
  isMobileMenuOpen: boolean
  setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  closeButtonRef: React.RefObject<HTMLDivElement>
  publicLayout: ReactQuery.PublicLayoutWidgetsItem[]
  shopNavigation: NavigationItem[]
  isTradeProgramRibbonHidden: boolean
  tradeRibbonHeight?: number
}

export const MobileMenu: React.FC<React.PWC<MobileMenuProps>> = ({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  closeButtonRef,
  publicLayout,
  shopNavigation,
  isTradeProgramRibbonHidden,
  tradeRibbonHeight = 0,
}) => {
  const [menu, setMenu] = useState<HTMLDivElement | null>(null)
  const [control, setControl] = useState<HTMLDivElement | null>(
    closeButtonRef.current
  )
  useClickOutside(
    () =>
      setTimeout(() => {
        setIsMobileMenuOpen(false)
        // Timeout because of problem with links behind the menu - they are clickable while closing menu
      }, 150),
    null,
    [control, menu]
  )

  const [scroll] = useWindowScroll()

  useEffect(() => {
    if (!control && closeButtonRef?.current) {
      setControl(closeButtonRef.current)
    }
  }, [closeButtonRef, control])

  const handleMenuClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
  }

  const publicLayoutMenuWidget = publicLayout?.find(
    (widget: ReactQuery.PublicLayoutWidgetsItem) =>
      // eslint-disable-next-line no-underscore-dangle
      widget.__component === 'widgets.public-layout-menu-widget'
  ) as ReactQuery.WidgetsPublicLayoutMenuWidgetComponent | undefined

  const onMenuClose = () => {
    setIsMobileMenuOpen(false)
  }

  useEffect(() => {
    // Toggle overflow on body when isMobileMenuOpen changes
    if (isMobileMenuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [isMobileMenuOpen])

  const getTopPosition = () => {
    if (isTradeProgramRibbonHidden) {
      return 0
    }
    const { y } = scroll
    if (y < tradeRibbonHeight) {
      return tradeRibbonHeight - y
    }
    return 0
  }

  return (
    <Box
      style={{ transition: 'all 0.3s ease-in-out', pointerEvents: 'all' }}
      right={isMobileMenuOpen ? 0 : '-100%'}
      position="fixed"
      top={`calc(${HEADER_HEIGHT_MOBILE_PARTIAL} + ${getTopPosition()}px)`}
      height="100%"
      width="100%"
      zIndex={9999999}
    >
      <Flex
        height={`calc(100% - ${HEADER_HEIGHT_MOBILE_PARTIAL} - ${getTopPosition()}px)`}
      >
        <Box
          width={SIDE_MENU_CONTENT}
          height="100%"
          onClick={handleMenuClick}
        />
        <Box
          overflowY="auto"
          height="100%"
          width={`calc(100vw - ${SIDE_MENU_CONTENT})`}
          backgroundColor={COLOR_INTENT.MENU.BACKGROUND}
          py={SPACE.PX_10}
          overflowX="hidden"
          ref={setMenu}
        >
          <Box px={SPACE.PX_32}>
            <Box mb={SPACE.PX_10}>
              <MenuLink href={ROUTE.SHOWROOM()} onClick={onMenuClose}>
                Shop
              </MenuLink>
            </Box>
            {shopNavigation.length > 0 && (
              <Box>
                <Accordion
                  styles={{
                    root: { paddingBottom: SPACE.PX_16 },
                    content: { paddingBottom: 0 },
                  }}
                >
                  {shopNavigation.map((navigationItem) => (
                    <React.Fragment key={navigationItem.title}>
                      {navigationItem.children.length > 0 ? (
                        <Accordion.Item
                          value={navigationItem.title}
                          styles={{
                            item: {
                              border: 'none',
                            },
                          }}
                        >
                          <Accordion.Control
                            styles={{
                              control: {
                                background: 'transparent !important',
                                paddingLeft: SPACE.PX_10,
                                paddingRight: 0,
                              },
                              label: {
                                fontSize: 16,
                                textTransform: 'uppercase',
                                letterSpacing: '0.06em',
                                paddingTop: SPACE.PX_8,
                                paddingBottom: SPACE.PX_8,
                              },
                            }}
                          >
                            {navigationItem.title}
                          </Accordion.Control>
                          <Accordion.Panel
                            styles={{
                              content: {
                                padding: 0,
                              },
                            }}
                          >
                            <Accordion
                              styles={{
                                root: { paddingBottom: SPACE.PX_8 },
                                content: { paddingBottom: 0 },
                              }}
                            >
                              {navigationItem.children.map((children) => (
                                <React.Fragment key={children.title}>
                                  {children.children.length > 0 ? (
                                    <Accordion.Item
                                      key={children.title}
                                      value={children.title}
                                      styles={{
                                        item: {
                                          border: 'none',
                                        },
                                      }}
                                    >
                                      <Accordion.Control
                                        styles={{
                                          control: {
                                            backgroundColor:
                                              'transparent !important',
                                            paddingLeft: SPACE.PX_20,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                          },
                                          label: {
                                            fontSize: 16,
                                            fontWeight: 500,
                                            paddingTop: SPACE.PX_8,
                                            paddingBottom: SPACE.PX_8,
                                          },
                                        }}
                                      >
                                        {children.title}
                                      </Accordion.Control>
                                      <Accordion.Panel>
                                        {children.children.map(
                                          (subChildren, index) => (
                                            <Link
                                              href={subChildren.path}
                                              onClick={onMenuClose}
                                              key={index}
                                            >
                                              <Text
                                                styles={{
                                                  root: {
                                                    color:
                                                      COLOR_INTENT.MENU
                                                        .MOBILE_NAVIGATION_CATEGORY_LINK
                                                        .COLOR,
                                                    fontSize: FONT_SIZE.PX_16,
                                                    paddingTop: SPACE.PX_8,
                                                    paddingBottom: SPACE.PX_8,
                                                    paddingLeft: SPACE.PX_20,
                                                    paddingRight: 0,
                                                  },
                                                }}
                                              >
                                                {subChildren.title}
                                              </Text>
                                            </Link>
                                          )
                                        )}
                                        <Link
                                          href={children.path}
                                          onClick={onMenuClose}
                                        >
                                          <Text
                                            styles={{
                                              root: {
                                                color:
                                                  COLOR_INTENT.MENU
                                                    .MOBILE_NAVIGATION_CATEGORY_LINK
                                                    .COLOR,
                                                fontSize: FONT_SIZE.PX_16,
                                                paddingTop: SPACE.PX_8,
                                                paddingBottom: SPACE.PX_8,
                                                paddingLeft: SPACE.PX_20,
                                                paddingRight: 0,
                                              },
                                            }}
                                          >
                                            All {children.title}
                                          </Text>
                                        </Link>
                                      </Accordion.Panel>
                                    </Accordion.Item>
                                  ) : (
                                    <Link
                                      href={children.path}
                                      onClick={onMenuClose}
                                    >
                                      <Text
                                        styles={{
                                          root: {
                                            color:
                                              COLOR_INTENT.MENU
                                                .MOBILE_NAVIGATION_CATEGORY_LINK
                                                .COLOR,
                                            fontSize: FONT_SIZE.PX_16,
                                            paddingTop: SPACE.PX_8,
                                            paddingBottom: SPACE.PX_8,
                                            paddingLeft: SPACE.PX_20,
                                            paddingRight: 0,
                                            fontWeight: 500,
                                          },
                                        }}
                                      >
                                        {children.title}
                                      </Text>
                                    </Link>
                                  )}
                                </React.Fragment>
                              ))}
                              <Link
                                href={navigationItem.path}
                                onClick={onMenuClose}
                              >
                                <Text
                                  styles={{
                                    root: {
                                      color:
                                        COLOR_INTENT.MENU
                                          .MOBILE_NAVIGATION_CATEGORY_LINK
                                          .COLOR,
                                      fontSize: FONT_SIZE.PX_16,
                                      paddingTop: SPACE.PX_8,
                                      paddingBottom: SPACE.PX_8,
                                      paddingLeft: SPACE.PX_20,
                                      paddingRight: 0,
                                      fontWeight: 500,
                                    },
                                  }}
                                >
                                  All {navigationItem.title}
                                </Text>
                              </Link>
                            </Accordion>
                          </Accordion.Panel>
                        </Accordion.Item>
                      ) : (
                        <Link href={navigationItem.path} onClick={onMenuClose}>
                          <Text
                            styles={{
                              root: {
                                textTransform: 'uppercase',
                                color:
                                  COLOR_INTENT.MENU
                                    .MOBILE_NAVIGATION_CATEGORY_LINK.COLOR,
                                fontSize: FONT_SIZE.PX_16,
                                paddingTop: SPACE.PX_8,
                                paddingBottom: SPACE.PX_8,
                                paddingInline: SPACE.PX_10,
                                letterSpacing: '0.05em',
                              },
                            }}
                          >
                            {navigationItem.title}
                          </Text>
                        </Link>
                      )}
                    </React.Fragment>
                  ))}
                </Accordion>
              </Box>
            )}
          </Box>
          <Box
            borderColor={COLOR_NAMES.GRAY_SHINGLE}
            borderTop="1px solid"
            borderBottom="1px solid"
            py={SPACE.PX_10}
          >
            <Box my={SPACE.PX_16} px={SPACE.PX_32}>
              <MenuLink href={ROUTE.CONSULTATIONS()} onClick={onMenuClose}>
                Consultations
              </MenuLink>
            </Box>
            <Box mb={SPACE.PX_16} px={SPACE.PX_32}>
              <MenuLink href={ROUTE.BLOG_LIST()} onClick={onMenuClose}>
                Stories
              </MenuLink>
            </Box>
            <Box mb={SPACE.PX_16} px={SPACE.PX_32}>
              <MenuLink href={ROUTE.TRADE()} onClick={onMenuClose}>
                Trade Program
              </MenuLink>
            </Box>
          </Box>
          {publicLayoutMenuWidget?.trendingLinks.length > 0 && (
            <Box pl={SPACE.PX_10} my={SPACE.PX_16}>
              {publicLayoutMenuWidget?.trendingLinks?.map((trendingLink) => (
                <Box key={trendingLink.id}>
                  <Link href={trendingLink.link}>
                    <Text
                      styles={{
                        root: {
                          color:
                            COLOR_INTENT.MENU.MOBILE_NAVIGATION_CATEGORY_LINK
                              .COLOR,
                          fontSize: FONT_SIZE.PX_16,
                          paddingTop: SPACE.PX_8,
                          paddingBottom: SPACE.PX_8,
                          paddingLeft: SPACE.PX_20,
                          paddingRight: 0,
                        },
                      }}
                    >
                      {trendingLink.label}
                    </Text>
                  </Link>
                </Box>
              ))}
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  )
}
