import { useWindowScroll } from '@mantine/hooks'
import React, { useEffect, useState } from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import { Box, Flex } from 'components/Layout'
import Link from 'components/Link'
import { HeaderLogo } from 'components/Logo'
import { HEADER_HEIGHT_VALUES } from 'constants/common'
import { ROUTE } from 'constants/routes'
import { SPACE } from 'Theme'
import { MOBILE_MENU_MARGIN } from '../constants'
import { DesktopNavItem } from './DesktopNavItem'

interface DesktopNavigationProps {
  searchComponent: React.ReactNode
  iconsMenu: React.ReactNode
  headerLogoTitleId: string
  setIsShopHovered: React.Dispatch<React.SetStateAction<boolean>>
  isTradeRibbonHidden: boolean
  tradeRibbonHeight?: number
}

export const DesktopNavigation: React.FC<React.PWC<DesktopNavigationProps>> = ({
  searchComponent,
  iconsMenu,
  headerLogoTitleId,
  setIsShopHovered,
  isTradeRibbonHidden,
  tradeRibbonHeight = 0,
}) => {
  const [scroll] = useWindowScroll()
  const [actualScroll, setActualScroll] = useState(0)
  const { y } = scroll

  useEffect(() => {
    if (y !== actualScroll) {
      setIsShopHovered(false)
    }
  }, [y, actualScroll, setIsShopHovered])

  const navHeight = isTradeRibbonHidden
    ? HEADER_HEIGHT_VALUES.DESKTOP
    : HEADER_HEIGHT_VALUES.DESKTOP + tradeRibbonHeight
  return (
    <Flex
      width="100%"
      height="100%"
      alignItems="center"
      data-test-id={DATA_TEST_ID.DESKTOP_NAVIGATION}
    >
      <Box>
        <Link href={ROUTE.FRONT_PAGE()} aria-label="home">
          <HeaderLogo titleId={headerLogoTitleId} />
        </Link>
      </Box>
      <Flex height="100%" ml={MOBILE_MENU_MARGIN}>
        <DesktopNavItem
          onMouseEnter={() => {
            if (y > navHeight) {
              setIsShopHovered(true)
              setActualScroll(y)
            }
          }}
          title="Shop"
          href={ROUTE.SHOWROOM()}
        />

        <DesktopNavItem title="Consultations" href={ROUTE.CONSULTATIONS()} />
        <DesktopNavItem title="Stories" href={ROUTE.BLOG_LIST()} />
      </Flex>
      <Box width="100%">{searchComponent}</Box>
      <Box ml={{ MOBILE: SPACE.PX_12, DESKTOP: SPACE.PX_36 }}>
        <DesktopNavItem
          disabledMargin
          title="Trade Program"
          href={ROUTE.TRADE()}
        />
      </Box>
      {iconsMenu}
    </Flex>
  )
}
