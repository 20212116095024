import { useElementSize } from '@mantine/hooks'
import { ReactQuery } from 'cms-api'
import { get } from 'lodash'
import { useRouter } from 'next/router'
import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { Breadcrumbs } from 'components/Breadcrumbs'
import { Footer } from 'components/Footer'
import {
  CheckoutHeader,
  Header,
  HEADER_TYPE,
  type HeaderType,
} from 'components/Header'
import { MobileMenu } from 'components/Header/components/MobileMenu'
import { Box, Container, Flex } from 'components/Layout'
import { TradeProgramRibbon } from 'components/TradeProgramRibbon'
import { STRAPI_WIDGETS } from 'constants/common'
import { ROUTE } from 'constants/routes'
import { formatNavData } from 'routes/utils/formatNavMenu'
import { DEVICE, PAGE_MAX_WIDTH, SPACE } from 'Theme'
import { useGetBreadcrumbsByPathQuery } from 'types/graphql-generated'

const URL_PARAMS_REGEX = /(\?.*|#.*)/g

const Main = styled('main')`
  height: 100%;
`

export const PUBLIC_LAYOUT_SIDE_PADDING = SPACE.PX_15

const StyledContainer = styled(Container)`
  @media screen and (max-width: ${DEVICE.TABLET}) and (min-width: ${PAGE_MAX_WIDTH.PUBLIC_LAYOUT_MOBILE}) {
    padding: 0 ${PUBLIC_LAYOUT_SIDE_PADDING};
  }
`

const HEADER_COMPONENT = {
  [HEADER_TYPE.CHECKOUT]: CheckoutHeader,
  [HEADER_TYPE.DEFAULT]: Header,
}

interface PublicLayoutProps {
  headerType?: HeaderType
  isWithBreadcrumbs?: boolean
  isFullWidth?: boolean
}

const PublicLayout: React.FC<React.PWC<PublicLayoutProps>> = ({
  children,
  headerType = HEADER_TYPE.DEFAULT,
  isWithBreadcrumbs = false,
  isFullWidth = false,
}) => {
  const router = useRouter()
  const breadcrumbsFromServer = get(children, [
    'props',
    'children',
    'props',
    'breadcrumbs',
  ])
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const closeButtonRef = useRef<HTMLDivElement>(null)
  const { ref: tradeProgramRibbonRef, height: tradeRibbonHeight } =
    useElementSize()
  const {
    data: breadcrumbsData,
    previousData: previousBreadcrumbsData,
    error: breadcrumbsError,
  } = useGetBreadcrumbsByPathQuery({
    skip: !router.isReady || !isWithBreadcrumbs || breadcrumbsFromServer,
    variables: {
      path: router.asPath.replace(URL_PARAMS_REGEX, ''),
    },
  })

  const navigation = get(
    children,
    ['props', 'children', 'props', 'navigation'],
    []
  )

  const { data: publicLayoutData, isLoading } = ReactQuery.useGetPublicLayout(
    {
      populate: {
        widgets: {
          on: {
            'widgets.feature-bar-widget': {
              populate: '*',
            },
            'widgets.public-layout-menu-widget': {
              populate: '*',
            },
          },
        },
      } as never,
    },
    {
      query: {
        initialData: navigation.publicLayout,
        refetchOnMount: !navigation.publicLayout,
      },
    }
  )

  const { data: shopNavigationData, isLoading: isShopNavigationLoading } =
    ReactQuery.useGetNavigationRenderIdOrSlug('shop-navigation', {
      query: {
        initialData: navigation.shopNavigation,
        refetchOnMount: !navigation.shopNavigation,
      },
    })

  if (isLoading || isShopNavigationLoading) {
    return <Box />
  }

  const publicLayout = publicLayoutData?.data?.widgets

  const shopNavigation = formatNavData(shopNavigationData)

  const breadcrumbsSteps = isWithBreadcrumbs
    ? breadcrumbsFromServer ||
      breadcrumbsData?.getBreadcrumbsByPath ||
      previousBreadcrumbsData?.getBreadcrumbsByPath ||
      []
    : []

  const HeaderComponent = HEADER_COMPONENT[headerType]

  const isTradeProgramRibbonHidden =
    router.pathname === ROUTE.TRADE() ||
    headerType === HEADER_TYPE.CHECKOUT ||
    process.env.NEXT_PUBLIC_NODE_ENV !== 'production'

  const tradeRibbonHeightWithPadding = tradeRibbonHeight + 24

  const featureBarWidget = publicLayout?.find(
    (widget) =>
      // eslint-disable-next-line no-underscore-dangle
      widget.__component === STRAPI_WIDGETS.FEATURE_BAR_WIDGET
  ) as ReactQuery.WidgetsFeatureBarWidgetComponent | undefined

  return (
    <>
      <Flex
        minHeight="100vh"
        width="100%"
        height="100%"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Flex flexDirection="column">
          {!isTradeProgramRibbonHidden && featureBarWidget && (
            <TradeProgramRibbon
              featureBarWidget={featureBarWidget}
              ref={tradeProgramRibbonRef}
            />
          )}
          <HeaderComponent
            publicLayout={publicLayout}
            shopNavigation={shopNavigation}
            closeButtonRef={closeButtonRef}
            isMobileMenuOpen={isMobileMenuOpen}
            setIsMobileMenuOpen={setIsMobileMenuOpen}
            isTradeRibbonHidden={isTradeProgramRibbonHidden}
            tradeRibbonHeight={tradeRibbonHeightWithPadding}
          />
          {!breadcrumbsError && breadcrumbsSteps.length > 0 && (
            <StyledContainer>
              <Breadcrumbs py={SPACE.PX_16} steps={breadcrumbsSteps} />
            </StyledContainer>
          )}
          {isFullWidth ? (
            <Main>{children}</Main>
          ) : (
            <StyledContainer>
              <Main>{children}</Main>
            </StyledContainer>
          )}
        </Flex>
        <Footer />
      </Flex>
      <MobileMenu
        isMobileMenuOpen={isMobileMenuOpen}
        setIsMobileMenuOpen={setIsMobileMenuOpen}
        closeButtonRef={closeButtonRef}
        publicLayout={publicLayout}
        shopNavigation={shopNavigation}
        isTradeProgramRibbonHidden={isTradeProgramRibbonHidden}
        tradeRibbonHeight={tradeRibbonHeightWithPadding}
      />
    </>
  )
}

export const getPublicLayout = (
  page: React.ReactNode,
  publicLayoutProps: Omit<PublicLayoutProps, 'children'> = {}
) => <PublicLayout {...publicLayoutProps}>{page}</PublicLayout>
