import { useApolloClient } from '@apollo/client'
import { datadogRum } from '@datadog/browser-rum'
import { useStatsigUser } from '@statsig/react-bindings'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import {
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  fetchSignInMethodsForEmail,
  getAdditionalUserInfo,
  getMultiFactorResolver,
  getRedirectResult,
  multiFactor,
  onAuthStateChanged,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  signInWithCustomToken as firebaseSignInWithCustomToken,
  signOut as firebaseSignOut,
  reauthenticateWithCredential as firebaseReauthenticateWithCredential,
  signInWithEmailAndPassword,
  signInWithRedirect,
  unlink,
  updatePassword as firebaseUpdatePassword,
  deleteUser,
} from 'firebase/auth'
import { capitalize, isEmpty, pick, startsWith } from 'lodash'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import queryString from 'query-string'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useLocation } from 'react-use'
import { EMAILS } from 'shared-constants'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import { Box } from 'components/Layout'
import { Toast } from 'components/Toast'
import { SHOWROOM_CHECKOUT_STORAGE_KEY } from 'constants/common'
import { PUBLIC_ROUTE, ROUTE } from 'constants/routes'
import {
  getIsMFAEnrollRequired,
  getSignedInUserType,
  USER_TYPE,
  SIGN_UP_METHOD,
  formatUserMe,
} from 'providers/userAuthUtil'
import { CLIENT_STATUSES } from 'routes/admin/constants/general'
import { useMedia } from 'useMedia'
import { trackSetIdentity } from 'utils/analyticsTracking'
import { trackBingSignUp } from 'utils/bing'
import auth from 'utils/firebase'
import { formatUserName } from 'utils/formatters'
import { getFirebaseAuthErrorMessage } from 'utils/getFirebaseAuthErrorMessage'
import { klaviyoIdentifyUser, klaviyoSetUserLoginTime } from 'utils/klaviyo'
import { mapCustomizedTimezoneToTimezone } from 'utils/timezones'
import {
  CLIENT_SIGN_UP_MUTATION,
  FIX_USER_CLAIMS_MUTATION,
  USER_PROFILE_QUERY,
} from 'utils/userAuthQueries'
import packageJson from '../../package.json'
import { transformationInputDataToGA4DataLayer } from './googleAnalytics/transformation'
import { GA4_EVENTS } from './googleAnalytics/types'

const { ACTIVE, INACTIVE } = CLIENT_STATUSES

const FIREBASE_ALREADY_USED_EMAIL = 'auth/email-already-in-use'
const FIREBASE_MFA_ERROR = 'auth/multi-factor-auth-required'

export const MULTIFACTOR_AUTH_STEPS = {
  LOGIN: 'LOGIN',
  ENROLL: 'ENROLL',
  VERIFY_ENROLL: 'VERIFY_ENROLL',
  CONFIRM_CODE: 'CONFIRM_CODE',
}

const fallbackExecuteDataToDataLayer = ({ event, overrideData }) => {
  if (!window?.dataLayer || process.env.NEXT_PUBLIC_NODE_ENV === 'test') {
    return
  }

  window.dataLayer.push({
    event,
    ...transformationInputDataToGA4DataLayer({
      event,
      contextData: overrideData,
      query: overrideData.query,
    }),
  })
}

const trackSignToGoogleAnalytics = ({
  executeDataToDataLayer = fallbackExecuteDataToDataLayer,
  isSignUp = false,
  providerId,
  query,
}) => {
  const event = isSignUp ? GA4_EVENTS.SIGN_UP : GA4_EVENTS.LOGIN
  executeDataToDataLayer({
    event,
    overrideData: {
      providerId,
      query,
    },
  })
}

export const UserAuthContext = createContext()
export const useUserAuth = () => useContext(UserAuthContext)

const UserAuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isAdminLoggedInAsExpert, setIsAdminLoggedInAsExpert] = useState(false)
  const [isAdminLoggedInAsClient, setIsAdminLoggedInAsClient] = useState(false)
  const [signedInUserType, setSignedInUserType] = useState(USER_TYPE.NONE)
  const [multiFactorStep, setMultiFactorStep] = useState(
    MULTIFACTOR_AUTH_STEPS.LOGIN
  )
  const [isMFAEnrollRequired, setIsMFAEnrollRequired] = useState(false)
  const [multiFactorResolver, setMultiFactorResolver] = useState(null)
  const [verificationId, setVerificationId] = useState(null)
  const initialSignedInUserType = useRef(USER_TYPE.NONE)
  const isUserSigningUp = useRef(false)
  const isUserSigningOut = useRef(false)
  const apolloClient = useApolloClient()
  const router = useRouter()
  const media = useMedia()
  const location = useLocation()
  const query = queryString.parse(location.search)
  const temporaryUserData = useRef({})
  const { updateUserSync } = useStatsigUser()

  // This is used for GA4 to store the temporary data for user
  // We can store data for example in checkout form or contact Us form
  const setTemporaryUserData = (callback) => {
    temporaryUserData.current = callback(temporaryUserData.current)
  }

  /**
   Converts `date` to a new `Date` which represents value of the `date` like if it was defined in `client.timezone`.
   This has to be done as there is no way to represent `date` in different than local (browser) timezone.
   Don't use this date as a source of truth as this date **is shifted** by the difference between the 2 timezones.
   It works that way because **browser** can **only work** with dates **in local** timezone

   @see https://www.npmjs.com/package/date-fns-tz
   @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date
   */
  const convertZonedDateToUTC = useCallback(
    (date) =>
      user?.timezone
        ? zonedTimeToUtc(date, mapCustomizedTimezoneToTimezone(user.timezone))
        : date,
    [user]
  )

  const convertDateToTimezone = useCallback(
    (date = new Date()) =>
      user?.timezone
        ? utcToZonedTime(date, mapCustomizedTimezoneToTimezone(user.timezone))
        : date,
    [user]
  )

  const getIsUserSignedUpWithPassword = useCallback(
    () =>
      auth.currentUser?.providerData?.some(
        ({ providerId }) => providerId === SIGN_UP_METHOD.PASSWORD
      ),
    []
  )

  const getFirebaseIdToken = useCallback(
    () => auth.currentUser?.getIdToken(),
    []
  )

  const reauthenticateAndUpdatePassword = useCallback(
    async (currentPassword, newPassword) => {
      const { currentUser } = auth
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        currentPassword
      )
      try {
        await firebaseReauthenticateWithCredential(currentUser, credential)
        await firebaseUpdatePassword(currentUser, newPassword)
        Toast.success('Password successfully changed')
      } catch (error) {
        Toast.error(getFirebaseAuthErrorMessage(error))
      }
    },
    []
  )

  const signInWithCustomToken = useCallback(async ({ token }) => {
    isUserSigningOut.current = false
    try {
      await firebaseSignInWithCustomToken(auth, token)
    } catch (error) {
      Toast.error(error.message)
    }
  }, [])

  const signOut = useCallback(async () => {
    await firebaseSignOut(auth)
    /**
     * TODO: rework this
     * https://github.com/sudolabs-io/the-expert/issues/3643
     * */
    localStorage.removeItem(SHOWROOM_CHECKOUT_STORAGE_KEY)

    datadogRum.clearUser()
    updateUserSync({})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const thirdPartySignIn = useCallback((ProviderClass) => {
    isUserSigningOut.current = false
    setIsLoading(true)
    try {
      const provider = new ProviderClass()
      signInWithRedirect(auth, provider)
    } catch (error) {
      Toast.error(error.message)
    }
    setIsLoading(false)
  }, [])

  const refetchAndSetUserData = useCallback(
    async ({ userType } = {}) => {
      try {
        const { data } = await apolloClient.query({
          query: USER_PROFILE_QUERY,
          fetchPolicy: 'no-cache',
        })
        const { userMe } = data
        setUser((previous) => {
          const updated = formatUserMe(userMe)

          if (userMe) {
            const context = {
              ...pick(userMe, ['id', 'capabilities']),
              role: userMe.__typename,
            }

            datadogRum.setUser(context)
            updateUserSync({
              custom: {
                role: context.role,
                capabilities: context.capabilities,
              },
              userID: context.id,
              appVersion: packageJson.version,
            })
          }

          // Avoid updating the user if it's same as before - helps with rerendering
          return JSON.stringify(previous) === JSON.stringify(updated)
            ? previous
            : updated
        })
        const userEmail = userMe?.contactEmail ?? userMe?.email
        const userID = userMe?.id
        if (userEmail && userID) {
          trackSetIdentity({
            userEmail,
          })
        }
        if (userType) {
          setSignedInUserType(userType)
        }
      } catch (error) {
        if (error?.graphQLErrors && error.graphQLErrors.length > 0) {
          signOut()
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apolloClient, signOut]
  )

  /**
   * @returns {Promise<boolean>} `true` if sign-up was successful, `false` otherwise
   */
  const signUp = useCallback(
    async ({
      executeDataToDataLayer,
      firstName,
      lastName,
      email,
      password,
      returnUrl,
    }) => {
      // only clients can sign up
      isUserSigningOut.current = false
      setIsLoading(true)

      const SIGN_UP_MUTATION_ERROR_MESSAGE =
        'CREDENTIALS: Client sign-up mutation failed'

      let currentUser

      try {
        isUserSigningUp.current = true
        const { user: firebaseUser } = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        )
        currentUser = firebaseUser
        const idToken = await firebaseUser.getIdToken()
        const { data: createClientResponseData, errors: createClientErrors } =
          await apolloClient.mutate({
            mutation: CLIENT_SIGN_UP_MUTATION,
            variables: {
              input: {
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                returnUrl,
                idToken,
                firstName,
                lastName,
                contactEmail: email,
              },
            },
          })

        if (isEmpty(createClientResponseData) || !isEmpty(createClientErrors)) {
          throw new Error(SIGN_UP_MUTATION_ERROR_MESSAGE)
        }

        if (!createClientErrors) {
          await auth.currentUser.reload()
          await auth.currentUser.getIdTokenResult(true)
          await refetchAndSetUserData({ userType: USER_TYPE.CLIENT })
          isUserSigningUp.current = false
          trackBingSignUp()
          trackSignToGoogleAnalytics({
            executeDataToDataLayer,
            isSignUp: true,
          })
          return true
        }
      } catch (error) {
        const SIGN_UP_ERROR_MESSAGE =
          'Something went wrong with sign-up, please try again.'

        if (!currentUser) {
          Toast.error(SIGN_UP_ERROR_MESSAGE)
          return false
        }

        if (error.message === SIGN_UP_MUTATION_ERROR_MESSAGE) {
          try {
            await deleteUser(currentUser)
          } catch (err) {
            return false
          }
          Toast.error(SIGN_UP_ERROR_MESSAGE)
          return false
        }

        if (error.code === FIREBASE_ALREADY_USED_EMAIL) {
          Toast.error('The email address is already in use by another account.')
          return false
        }

        const message = getFirebaseAuthErrorMessage(error)
        Toast.error(message)
        return false
      }
      setIsLoading(false)
      isUserSigningUp.current = false
      return false
    },
    [apolloClient, refetchAndSetUserData]
  )

  const updatePassword = useCallback(async (password) => {
    setIsLoading(true)
    await firebaseUpdatePassword(auth.currentUser, password)
    setIsLoading(false)
  }, [])

  /**
   * First checks if it's a new user(sign up), if it's not, fetches the data
   * from our API and halts. If it's a new user, it gets the data from the
   * `redirectResult` param and stores it in the database. If it's a google
   * account(`ACTIVE` status), it fetches the data and keep him signed in.
   * Otherwise, sign him out.
   */
  const handleSocialRedirect = useCallback(
    async ({ token, redirectResult }) => {
      const SIGN_UP_MUTATION_ERROR_MESSAGE =
        'SOCIAL: Client sign-up mutation failed'

      const additionalUserInfo = getAdditionalUserInfo(redirectResult)
      const { providerId } = redirectResult
      if (!additionalUserInfo?.isNewUser) {
        // Don't register, just fetch user data
        await refetchAndSetUserData({ userType: USER_TYPE.CLIENT })
        trackSignToGoogleAnalytics({
          providerId,
          query,
        })
        return
      }

      // Register user into our database
      const { profile } = additionalUserInfo
      const isFacebookLogin = providerId === SIGN_UP_METHOD.FACEBOOK

      try {
        const { data: createClientResponseData, errors: createClientErrors } =
          await apolloClient.mutate({
            mutation: CLIENT_SIGN_UP_MUTATION,
            variables: {
              input: {
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                returnUrl: encodeURIComponent(window.location.href),
                firstName: isFacebookLogin
                  ? profile.first_name
                  : profile.given_name || profile.name,
                lastName: isFacebookLogin
                  ? profile.last_name
                  : profile.family_name || profile.name,
                idToken: token,
                contactEmail: profile.email,
              },
            },
          })

        if (isEmpty(createClientResponseData) || !isEmpty(createClientErrors)) {
          throw new Error(SIGN_UP_MUTATION_ERROR_MESSAGE)
        }

        trackSignToGoogleAnalytics({
          providerId,
          isSignUp: true,
          query,
        })
        if (createClientResponseData.clientSignUp.status === INACTIVE) {
          Toast.success(
            'Success! Please confirm your account in your email inbox.'
          )
          await signOut()
          return
        }
        if (createClientResponseData.clientSignUp.status === ACTIVE) {
          // google accounts are active right away
          await firebaseSignInWithCustomToken(
            auth,
            createClientResponseData.clientSignUp.authToken
          )
          await refetchAndSetUserData({ userType: USER_TYPE.CLIENT })
        }
      } catch (error) {
        if (error.message === SIGN_UP_MUTATION_ERROR_MESSAGE) {
          try {
            await deleteUser(redirectResult.user)
          } catch (err) {
            return
          }
          Toast.error('Something went wrong with sign-up, please try again.')
          return
        }

        if (!error.networkError) {
          await signOut()
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apolloClient, refetchAndSetUserData, signOut]
  )

  /**
   * Checks if it's admin-sign-in-as-expert. If it is, sets the specific token
   * for re-sign as admin and fetches expert data. If it's not, then it checks
   * if it's a social sign-in/up. If it is, calls `handleSocialRedirect`
   * function and stops. Otherwise, checks if it's the correct page that's being
   * used for the sign-in. If it's wrong, show him error message
   * and log him out. Otherwise, fetch data according to the user type.
   */
  const handleUserData = useCallback(async () => {
    const { currentUser } = auth
    const redirectResult = await getRedirectResult(auth)
    let { claims } = await currentUser.getIdTokenResult()
    let firebaseUserType = getSignedInUserType(claims)
    if (redirectResult && redirectResult.user !== null) {
      // is social redirect
      if (claims.isExpertUser || claims.isAdmin) {
        // prevent expert and admin user to have two providers
        if (
          currentUser.providerData.length > 1 &&
          currentUser.providerData.some(
            ({ providerId }) => providerId === SIGN_UP_METHOD.GOOGLE
          )
        ) {
          await unlink(currentUser, SIGN_UP_METHOD.GOOGLE)
        }

        Toast.error(
          claims.isAdmin
            ? 'Please use sign-in page for admin.'
            : 'Please use sign in tab for Experts and Trade.'
        )
        await signOut()
        return
      }
      const token = await currentUser.getIdToken()
      await handleSocialRedirect({
        token,
        redirectResult,
      })
      return
    }
    if (firebaseUserType === USER_TYPE.NONE) {
      await apolloClient.mutate({
        mutation: FIX_USER_CLAIMS_MUTATION,
        variables: {
          email: claims.email,
        },
      })
      await currentUser.reload()
      ;({ claims } = await currentUser.getIdTokenResult(true))
      firebaseUserType = getSignedInUserType(claims)
      if (firebaseUserType === USER_TYPE.NONE) {
        Toast.error(`Please contact support at ${EMAILS.CUSTOMER_SUPPORT}`)
        return
      }
    }
    if (getIsMFAEnrollRequired(currentUser, firebaseUserType)) {
      setIsMFAEnrollRequired(true)
      setMultiFactorStep(MULTIFACTOR_AUTH_STEPS.ENROLL)
      return
    }

    if (claims.adminUid) {
      // is admin sign in as expert
      if (claims.isExpertUser) {
        setIsAdminLoggedInAsExpert(true)
      }
      if (claims.isClient) {
        setIsAdminLoggedInAsClient(true)
      }
      await refetchAndSetUserData({ userType: firebaseUserType })
      return
    }
    if (initialSignedInUserType.current !== USER_TYPE.NONE) {
      // handle correct sign-in
      if (initialSignedInUserType.current !== firebaseUserType) {
        Toast.error(
          `Please use sign-in page for ${capitalize(firebaseUserType)}.`
        )
        await signOut()
        return
      }
    }
    await refetchAndSetUserData({ userType: firebaseUserType })
  }, [apolloClient, handleSocialRedirect, refetchAndSetUserData, signOut])

  const enrollMultiFactor = useCallback(
    async (phoneNumber, recaptchaVerifier) => {
      try {
        const session = await multiFactor(auth.currentUser).getSession()

        const phoneOpts = {
          phoneNumber,
          session,
        }

        const phoneAuthProvider = new PhoneAuthProvider(auth)

        const phoneVerficationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneOpts,
          recaptchaVerifier
        )

        setVerificationId(phoneVerficationId)
        setMultiFactorStep(MULTIFACTOR_AUTH_STEPS.VERIFY_ENROLL)
      } catch (error) {
        setVerificationId(null)
        setMultiFactorStep(MULTIFACTOR_AUTH_STEPS.ENROLL)
        Toast.error(getFirebaseAuthErrorMessage(error))
        await signOut()
      }

      setIsLoading(false)
    },
    [signOut]
  )

  const verifyMultiFactor = useCallback(
    async (resolver, recaptchaVerifier) => {
      try {
        setMultiFactorResolver(resolver)
        const phoneOpts = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        }

        const phoneAuthProvider = new PhoneAuthProvider(auth)

        const verificationIdToConfirm =
          await phoneAuthProvider.verifyPhoneNumber(
            phoneOpts,
            recaptchaVerifier
          )

        setVerificationId(verificationIdToConfirm)
        setMultiFactorStep(MULTIFACTOR_AUTH_STEPS.CONFIRM_CODE)
      } catch (error) {
        setMultiFactorStep(MULTIFACTOR_AUTH_STEPS.LOGIN)
        setMultiFactorResolver(null)
        setVerificationId(null)
        Toast.error(getFirebaseAuthErrorMessage(error))
        await signOut()
      }

      setIsLoading(false)
    },
    [signOut]
  )

  const signIn = useCallback(
    async (
      { executeDataToDataLayer, userType, email, password },
      recaptchaVerifier
    ) => {
      isUserSigningOut.current = false
      setIsLoading(true)
      try {
        const res = await signInWithEmailAndPassword(auth, email, password)

        if (res?.user?.metadata?.lastLoginAt) {
          const date = new Date(Number(res.user.metadata.lastLoginAt))
          const isoDate = date.toISOString()
          klaviyoSetUserLoginTime({
            user: res.user,
            date: isoDate,
          })
        }
        initialSignedInUserType.current = userType
        trackSignToGoogleAnalytics({
          executeDataToDataLayer,
          isSignUp: false,
        })
      } catch (error) {
        if (error?.code === FIREBASE_MFA_ERROR) {
          await verifyMultiFactor(
            getMultiFactorResolver(auth, error),
            recaptchaVerifier
          )
          return
        }

        const signInMethods =
          (await fetchSignInMethodsForEmail(auth, email)) ?? []
        let { message } = error
        if (signInMethods.includes(SIGN_UP_METHOD.GOOGLE)) {
          message = `You signed up using Google, Login by clicking “Sign in with Google”`
        } else if (!signInMethods.length) {
          message =
            'There is no account associated with this email. Create an account to login.'
        } else {
          message = getFirebaseAuthErrorMessage(error)
        }
        Toast.error(message)
        setIsLoading(false)
      }
    },
    [verifyMultiFactor]
  )

  const confirmMultiFactor = useCallback(
    async (code) => {
      try {
        const credential = PhoneAuthProvider.credential(verificationId, code)
        const multiFactorAssertion =
          PhoneMultiFactorGenerator.assertion(credential)
        await multiFactorResolver.resolveSignIn(multiFactorAssertion)
      } catch (error) {
        Toast.error(getFirebaseAuthErrorMessage(error))
        setMultiFactorStep(MULTIFACTOR_AUTH_STEPS.LOGIN)
        setMultiFactorResolver(null)
        setVerificationId(null)
        setIsLoading(false)
        await signOut()
      }
    },
    [multiFactorResolver, verificationId, signOut]
  )

  const verifyEnrollMultiFactor = useCallback(
    async (code) => {
      try {
        const cred = PhoneAuthProvider.credential(verificationId, code)

        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

        const { currentUser } = auth
        await multiFactor(currentUser).enroll(
          multiFactorAssertion,
          'phone number'
        )
        setVerificationId(null)
        setIsMFAEnrollRequired(false)
        await handleUserData()
        setMultiFactorStep(MULTIFACTOR_AUTH_STEPS.LOGIN)
      } catch (error) {
        setVerificationId(null)
        setMultiFactorStep(MULTIFACTOR_AUTH_STEPS.LOGIN)
        Toast.error(getFirebaseAuthErrorMessage(error))
      }

      setIsLoading(false)
    },
    [verificationId, handleUserData]
  )

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        // User is signed in
        if (!isUserSigningUp.current) {
          await handleUserData()
          setIsLoading(false)
        }
      } else {
        if (signedInUserType !== USER_TYPE.NONE) {
          isUserSigningOut.current = true
        }
        // User is not signed in
        setUser(null)
        setIsMFAEnrollRequired(false)
        setSignedInUserType(USER_TYPE.NONE)
        setMultiFactorStep(MULTIFACTOR_AUTH_STEPS.LOGIN)
        initialSignedInUserType.current = USER_TYPE.NONE
        setIsAdminLoggedInAsExpert(false)
        setIsAdminLoggedInAsClient(false)
      }
      setIsPageLoading(false)
    })
    return () => unsubscribe()
  }, [handleUserData, signedInUserType])

  useEffect(() => {
    if (process.browser && Boolean(window.Intercom)) {
      window.Intercom('boot', {
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
      })
    }
  }, [])

  useEffect(() => {
    if (
      process.browser &&
      Boolean(window.Intercom) &&
      !isAdminLoggedInAsClient &&
      !isAdminLoggedInAsExpert
    ) {
      if (
        signedInUserType === USER_TYPE.CLIENT ||
        signedInUserType === USER_TYPE.EXPERT
      ) {
        // logged in, fill with data according to state
        window.Intercom('update', {
          email: user?.loginEmail ?? user?.email,
          name: formatUserName({ user, signedInUserType }) || null,
          created_at: user?.createdAtUtc.isoFormat,
        })
      }
    }
  }, [user, signedInUserType, isAdminLoggedInAsClient, isAdminLoggedInAsExpert])

  useEffect(() => {
    if (process.browser && Boolean(window.Intercom)) {
      window.Intercom('update', {
        hide_default_launcher:
          (!PUBLIC_ROUTE.some((route) => route === router.pathname) &&
            media.MOBILE) ||
          startsWith(router.pathname, ROUTE.ADMIN()),
      })
    }
  }, [router, media])

  useEffect(() => {
    if (signedInUserType === USER_TYPE.CLIENT && !!user) {
      klaviyoIdentifyUser(user)
    }
  }, [signedInUserType, user])

  const contextValues = useMemo(
    () => ({
      user,
      isPageLoading,
      isLoading,
      isAdminLoggedInAsExpert,
      isAdminLoggedInAsClient,
      signedInUserType,
      isClientSignedIn: signedInUserType === USER_TYPE.CLIENT,
      isExpertSignedIn: signedInUserType === USER_TYPE.EXPERT,
      isAdminSignedIn: signedInUserType === USER_TYPE.ADMIN,
      isNoneSignedIn: signedInUserType === USER_TYPE.NONE,
      isUserSigningUp,
      multiFactorStep,
      isMFAEnrollRequired,
      convertZonedDateToUTC,
      convertDateToTimezone,
      getIsUserSignedUpWithPassword,
      getFirebaseIdToken,
      reauthenticateAndUpdatePassword,
      signIn,
      confirmMultiFactor,
      enrollMultiFactor,
      verifyEnrollMultiFactor,
      signUp,
      signOut,
      updatePassword,
      thirdPartySignIn,
      refetchAndSetUserData,
      signInWithCustomToken,
      setIsPageLoading,
      isUserSigningOut,
      setTemporaryUserData,
      temporaryUserData,
    }),
    [
      confirmMultiFactor,
      convertDateToTimezone,
      convertZonedDateToUTC,
      enrollMultiFactor,
      getFirebaseIdToken,
      getIsUserSignedUpWithPassword,
      isAdminLoggedInAsClient,
      isAdminLoggedInAsExpert,
      isLoading,
      isMFAEnrollRequired,
      isPageLoading,
      multiFactorStep,
      reauthenticateAndUpdatePassword,
      refetchAndSetUserData,
      signIn,
      signInWithCustomToken,
      signOut,
      signUp,
      signedInUserType,
      thirdPartySignIn,
      updatePassword,
      user,
      verifyEnrollMultiFactor,
    ]
  )

  return (
    <UserAuthContext.Provider value={contextValues}>
      {/* Sign-in check */}
      {user && (
        <Box data-test-id={DATA_TEST_ID.LOGGED_IN_INDICATOR} display="none" />
      )}
      {children}
    </UserAuthContext.Provider>
  )
}

UserAuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default UserAuthProvider
