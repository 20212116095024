import dynamic from 'next/dynamic'
import React from 'react'
import styled from 'styled-components'
import { type SpaceProps } from 'styled-system'
import { Flex, Grid } from 'components/Layout'
import { LinkWithText, type LinkWithTextProps } from 'components/Link'
import { H5, Text } from 'components/Typography'
import {
  EXPERT_SHOWROOMS_PAGE_SLUG,
  PROCUREMENT_SLUG,
  PRODUCTS_PAGE_SLUG,
} from 'constants/common'
import { environment } from 'constants/environment'
import { ROUTE } from 'constants/routes'
import { useModal } from 'hooks'
import { COLOR_INTENT, FONT_SIZE, FONT_STACK, SPACE } from 'Theme'
import { useMedia } from 'useMedia'

const ContactUsModal = dynamic(
  () =>
    import('components/ContactUsModal/ContactUsModal').then(
      (module) => module.ContactUsModal
    ),
  {
    ssr: false,
  }
)

const StyledText = styled(Text)`
  cursor: pointer;
  color: ${COLOR_INTENT.FOOTER.NAVIGATION_LINKS.TEXT};
  width: fit-content;
  &:hover,
  &:active {
    text-decoration: underline;
  }
`

const StyledTextLink = styled(LinkWithText)`
  color: ${COLOR_INTENT.FOOTER.NAVIGATION_LINKS.TEXT};
  line-height: 1.4;
  &:hover,
  &:active {
    text-decoration: underline;
  }
`

const StyledLinkWithText: React.FC<React.PWC<LinkWithTextProps>> = ({
  children,
  ...rest
}) => (
  <StyledTextLink
    width="fit-content"
    fontSize={{ MOBILE: FONT_SIZE.PX_18, DESKTOP: FONT_SIZE.PX_13 }}
    {...rest}
  >
    {children}
  </StyledTextLink>
)

interface NavigationLinksColumnProps extends SpaceProps {
  title: string
}

const NavigationLinksColumn: React.FC<
  React.PWC<NavigationLinksColumnProps>
> = ({ title, children, ...rest }) => {
  const media = useMedia()

  return (
    <Flex flexDirection="column" {...rest}>
      <H5
        fontFamily={FONT_STACK.SANS}
        color={COLOR_INTENT.FOOTER.NAVIGATION_LINKS.HEADING}
        mb={media.MOBILE ? SPACE.PX_20 : SPACE.PX_16}
        fontSize={media.MOBILE ? FONT_SIZE.PX_20 : FONT_SIZE.PX_13}
      >
        {title}
      </H5>
      <Flex flexDirection="column" gap={SPACE.PX_12}>
        {children}
      </Flex>
    </Flex>
  )
}

export const NavigationLinks = () => {
  const { isOpen, closeModal, toggleModal } = useModal()
  const media = useMedia()

  const handleClickChatWithUs = () => {
    if (environment.CUSTOMER_SERVICE_FRAMEWORK === 'intercom') {
      window?.Intercom?.('show')
    } else {
      window?.FrontChat?.('show')
    }
  }

  return (
    <>
      <Grid
        gridTemplateColumns={{
          MOBILE: 'repeat(2, 1fr)',
          TABLET: 'repeat(4, 1fr)',
        }}
        gridColumnGap={SPACE.PX_16}
        gridRowGap={media.DESKTOP ? SPACE.PX_12 : SPACE.PX_24}
        my={{ MOBILE: SPACE.PX_32, TABLET: 'unset' }}
      >
        <NavigationLinksColumn title="Get advice">
          <StyledLinkWithText href={ROUTE.CONSULTATIONS()}>
            Consultations
          </StyledLinkWithText>
          <StyledLinkWithText href={ROUTE.EXPERTS()}>
            Find an expert
          </StyledLinkWithText>
          <StyledLinkWithText href={ROUTE.BLOG_LIST()}>
            Stories
          </StyledLinkWithText>
        </NavigationLinksColumn>
        <NavigationLinksColumn title="Shop">
          <StyledLinkWithText href={ROUTE.SHOWROOM()}>
            Overview
          </StyledLinkWithText>
          <StyledLinkWithText
            href={ROUTE.SHOWROOM_DETAIL({ slug: EXPERT_SHOWROOMS_PAGE_SLUG })}
          >
            Expert showrooms
          </StyledLinkWithText>
          <StyledLinkWithText href={ROUTE.SHOWROOM_BRANDS()}>
            Brands
          </StyledLinkWithText>
          <StyledLinkWithText
            href={ROUTE.SHOWROOM_DETAIL({ slug: PRODUCTS_PAGE_SLUG })}
          >
            Shop all
          </StyledLinkWithText>
        </NavigationLinksColumn>
        <NavigationLinksColumn title="Support">
          <StyledLinkWithText href={ROUTE.COUPON_CHECKOUT()}>
            Gift card
          </StyledLinkWithText>
          <StyledLinkWithText href={ROUTE.FAQ()}>FAQ</StyledLinkWithText>
          <StyledLinkWithText
            href={ROUTE.BLOG_DETAIL({ slug: PROCUREMENT_SLUG })}
          >
            Procurement
          </StyledLinkWithText>
          <StyledText
            fontSize={{ MOBILE: FONT_SIZE.PX_18, DESKTOP: FONT_SIZE.PX_13 }}
            onClick={handleClickChatWithUs}
          >
            Chat with us
          </StyledText>
          <StyledText
            fontSize={{ MOBILE: FONT_SIZE.PX_18, DESKTOP: FONT_SIZE.PX_13 }}
            onClick={toggleModal}
          >
            Email us
          </StyledText>
        </NavigationLinksColumn>
        <NavigationLinksColumn title="Company">
          <StyledLinkWithText href={ROUTE.CAREERS()}>
            Careers
          </StyledLinkWithText>
          <NavigationLinksColumn title="Trade" mt={SPACE.PX_8}>
            <StyledLinkWithText href={ROUTE.TRADE()}>
              Trade Program
            </StyledLinkWithText>
          </NavigationLinksColumn>
        </NavigationLinksColumn>
      </Grid>

      <ContactUsModal
        isModalOpen={isOpen}
        closeModal={closeModal}
        type="Footer"
      />
    </>
  )
}
