import { clearAllBodyScrollLocks } from 'body-scroll-lock'
import { type ReactQuery } from 'cms-api'
import React, { useEffect, useState } from 'react'
import { DATA_TEST_ID } from 'shared-constants/build/testIds'
import styled from 'styled-components'
import { Box, Container } from 'components/Layout'
import NotVerifiedEmailHeader from 'components/NotVerifiedEmailHeader'
import { SignedInAsUserHeader } from 'components/SignedInAsUserHeader'
import { HEADER_HEIGHT_VALUES, STRAPI_WIDGETS } from 'constants/common'
import { useUserAuth } from 'providers/userAuth'
import { type NavigationItem } from 'routes/utils/formatNavMenu'
import { COLOR_INTENT, DEVICE, Z_INDEX } from 'Theme'
import { CategoriesNavigation } from './components/CategoriesNavigation'
import { Navigation } from './components/Navigation'
import { TrendingLinks } from './components/TrendingLinks'

const SignedInAsExpertContainer = styled(Container)`
  background-color: ${COLOR_INTENT.HEADER.WRAPPER};
  margin: auto;
  padding: 0;
`

const NavigationWrapper = styled(Box)`
  display: block;
  background-color: ${COLOR_INTENT.HEADER.WRAPPER};
  @media screen and (max-width: ${DEVICE.TABLET}) {
    display: none;
  }
`

const ScrolledNavigationWrapper = styled(Box)`
  display: block;
  position: fixed;
  width: 100%;
  top: ${({ isShopHovered }) =>
    isShopHovered ? `${HEADER_HEIGHT_VALUES.DESKTOP}px` : '-100px'};
  transition: all 0.3s ease-out;
  background-color: ${COLOR_INTENT.HEADER.WRAPPER};
  z-index: 29;
  @media screen and (max-width: ${DEVICE.TABLET}) {
    display: none;
  }
`

export const Header: React.FC<
  React.PWC<{
    publicLayout: ReactQuery.PublicLayoutWidgetsItem[]
    shopNavigation: NavigationItem[]
    closeButtonRef: React.RefObject<HTMLDivElement>
    isMobileMenuOpen: boolean
    setIsMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
    isTradeRibbonHidden: boolean
    tradeRibbonHeight?: number
  }>
> = ({
  publicLayout,
  shopNavigation,
  closeButtonRef,
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  isTradeRibbonHidden,
  tradeRibbonHeight = 0,
}) => {
  const [isShopHovered, setIsShopHovered] = useState(false)
  const userAuth = useUserAuth()

  useEffect(clearAllBodyScrollLocks, [])

  const publicLayoutMenuWidget = publicLayout?.find(
    (widget) =>
      // eslint-disable-next-line no-underscore-dangle
      widget.__component === STRAPI_WIDGETS.PUBLIC_LAYOUT_MENU_WIDGET
  ) as ReactQuery.WidgetsPublicLayoutMenuWidgetComponent | undefined

  return (
    <>
      <Box
        position="sticky"
        top={0}
        width="inherit"
        zIndex={Z_INDEX.NAVBAR}
        data-test-id={DATA_TEST_ID.HEADER}
        backgroundColor={COLOR_INTENT.HEADER.WRAPPER}
      >
        {userAuth?.user &&
          !userAuth?.user?.isEmailVerified &&
          userAuth.isClientSignedIn && (
            <Box backgroundColor={COLOR_INTENT.CALLOUT.DANGER.BACKGROUND}>
              <SignedInAsExpertContainer>
                <NotVerifiedEmailHeader />
              </SignedInAsExpertContainer>
            </Box>
          )}
        {(userAuth?.isAdminLoggedInAsExpert ||
          userAuth?.isAdminLoggedInAsClient) && (
          <Box backgroundColor={COLOR_INTENT.CALLOUT.PRIMARY.BACKGROUND}>
            <SignedInAsExpertContainer>
              <SignedInAsUserHeader />
            </SignedInAsExpertContainer>
          </Box>
        )}
        <Navigation
          publicLayout={publicLayout}
          shopNavigation={shopNavigation}
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          closeButtonRef={closeButtonRef}
          setIsShopHovered={setIsShopHovered}
          isShopHovered={isShopHovered}
          isTradeRibbonHidden={isTradeRibbonHidden}
          tradeRibbonHeight={tradeRibbonHeight}
        />
      </Box>
      <NavigationWrapper>
        <CategoriesNavigation categoriesNavigation={shopNavigation} />

        {publicLayoutMenuWidget && (
          <TrendingLinks publicLayoutMenuWidget={publicLayoutMenuWidget} />
        )}
      </NavigationWrapper>

      <ScrolledNavigationWrapper isShopHovered={isShopHovered}>
        <CategoriesNavigation categoriesNavigation={shopNavigation} />

        {publicLayoutMenuWidget && (
          <TrendingLinks publicLayoutMenuWidget={publicLayoutMenuWidget} />
        )}
      </ScrolledNavigationWrapper>
    </>
  )
}
